import React from 'react'

function ReturnPolicy() {
  return (
    <div className='container py-4 text-start'>

      <h4>
        <b>Return & Refund Policy </b>   </h4>
      {/* <h7>Last updated: August 29, 2024</h7> */}
      <br /> <br />
      <p>Thank you for shopping at Gramapays.</p>
      <p>
        If you are not fully satisfied with your purchase, we encourage you to review our return and refund policy. This policy has been crafted with the help of the Return and Refund Policy Generator.
        <br /><br />
        The following terms apply to any products you purchase from us.
        <br /> <br /><h4> <b>Interpretation and Definitions</b></h4>
        <h5><b>Interpretation</b></h5><br />
        The words with capitalized initial letters have specific meanings as outlined below. These definitions apply whether the terms appear in singular or plural form.
        <br />
        <h4>
          <b>Definitions</b>
        </h4>
        <ul>
          <li><b>Company </b> (referred to as "the Company", "We", "Us", or "Our") refers to Gramapays, located at H- 91 Ground Floor, Sector-63, Noida, Gautam Buddha Nagar, UP-201301</li>
          <li><b>Goods  </b> refer to the products available for sale through our Service.</li>
          <li><b> Orders</b> mean a request placed by you to purchase Goods from us.</li>
          <li><b> Service </b> refers to the Website.</li>
          

        </ul>
        <h5><b>Order Cancellation Rights</b></h5>

        You have the right to cancel your order within 7 days without providing any reason. <br />

        The cancellation period will expire 7 days from the date on which you, or a third party (other than the carrier) appointed by you, takes possession of the Goods.

        To cancel your order, you must notify us with a clear declaration of your decision. You can inform us via:
        <ul><li>
          <b>Email: </b> info@gramapays.com</li></ul>
        Once we receive your cancellation notice, we will process your refund within 14 days of receiving the returned Goods. Refunds will be issued using the original payment method, and you will not incur any fees for this reimbursement.



        <br /> <br />
        <h5><b> Conditions for Returns</b></h5>
        To be eligible for a return, the following conditions must be met:
        <ul><li>
          The Goods were purchased within the last 7 days.</li>
           <li>The Goods are in their original packaging.</li>
        </ul>
        Certain Goods are exempt from returns, including:
        <ul> <li>
        Custom or personalized Goods.</li>
        <li>
        Goods that deteriorate quickly or are past their expiry date.</li>
        <li>
        Goods that are not suitable for return due to health or hygiene reasons if unsealed after delivery.
        </li><li>Goods that have been inseparably mixed with other items after delivery.
        </li> </ul>
        We reserve the right to refuse returns that do not meet these conditions at our sole discretion.
<br />
        Only regular-priced Goods may be refunded. Sale items are not eligible for refunds unless otherwise required by applicable law.
     <br /> <br />
     <h5><b>Returning Goods</b></h5> 
     You are responsible for covering the cost and risk of returning the Goods to us. Please send the items to the following address:
<br /> <b> H-61, 2nd Floor, Office No-05, Sector-63, Noida, G.B. Nagar U.P-201301.
</b>
<br />

We are not liable for any Goods lost or damaged in return shipping. Therefore, we recommend using a trackable and insured mail service. Refunds cannot be issued without proof of return delivery or receipt of the Goods.
     
<br /><br />
     <h5><b>Gifts</b></h5>
     If the Goods were marked as a gift when purchased and shipped directly to you, you will receive a gift credit equivalent to the value of the returned item. Once the return is received, a gift certificate will be mailed to you.
<br />
If the Goods were not marked as a gift or were shipped to the gift giver first, the refund will be sent to the original purchaser.
      
      <br /> <br />
      <h5><b>Contact Us</b></h5>
If you have any questions about our Return and Refund Policy, feel free to contact us:
<ul><li><b>
Email: </b> info@gramapays.com</li></ul>
      </p>

    </div>
  )
}

export default ReturnPolicy