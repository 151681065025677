import React from 'react';

function AboutUs() {
  return (
    <div className="container p-2">
      <h3>About Gramapays : Redefining E-Commerce Excellence</h3>
      <p>
        Welcome to Gramapays , your one-stop destination for quality products at the best prices. At Gramapays , we believe in providing a seamless shopping experience that blends innovation, convenience, and customer satisfaction. Join us as we share the inspiring story that drives our brand, Gramapays  E-Commerce.
      </p>
      <p>
        Gramapays 's mission is to elevate your shopping experience by offering a diverse range of products that meet the highest standards of quality and affordability.  Gramapays  is here to guide you through a rewarding and enjoyable online shopping journey.
      </p>
      <p>
        We launched Gramapays  in 2024 with a vision to support consumers looking for a trustworthy and convenient platform to discover a wide variety of products. Gramapays  emerged as a trusted brand, reflecting our dedication to quality, customer service, and a shared passion for making online shopping an enjoyable experience for all.
      </p>
      
      <h4>The Essence of Gramapays </h4>
      
      <h5>Mission Statement</h5>
      <p>
        At Gramapays , our mission is not just to sell products; it's to provide our customers with a platform that enhances their shopping experience by offering variety, quality, and value. We strive to be more than a marketplace; we aim to be a place where customer satisfaction is prioritized at every step.
      </p>

      <h5>Vision</h5>
      <p>
        Our vision is to create an online shopping environment where customers of all kinds can easily find the products they need, enjoy great deals, and trust the quality and service they receive. Gramapays  seeks to be recognized for its innovation, superior customer care, and dedication to providing a seamless and enjoyable shopping experience.
      </p>

      <h4>What Sets Gramapays  Apart</h4>
      
      <h5>Quality Assurance</h5>
      <p>
        Quality is at the core of Gramapays . Every product we offer is carefully selected to ensure it meets the highest standards. From sourcing reputable brands to ensuring thorough product testing, we guarantee that our customers receive only the best, making Gramapays  a reliable choice for online shopping.
      </p>
      
      <h5>Customer-Centric Approach</h5>
      <p>
        Gramapays  takes pride in being customer-focused. We believe every shopper is unique, and we are committed to providing personalized recommendations, guidance, and support throughout your shopping journey. Whether you’re looking for the latest tech gadgets, home essentials, or fashion trends, Gramapays  is here to meet your needs.
      </p>
      
      <h5>Innovation and Convenience</h5>
      <p>
        At Gramapays , we keep pace with the latest trends in technology and consumer needs. Our user-friendly website, combined with intuitive search options and a fast checkout process, makes your shopping experience quick, simple, and enjoyable. Innovation isn’t just about products—it’s about creating a seamless experience for our users.
      </p>
      
      <h4>Our Commitment to You</h4>
      
      <h5>Exceptional Customer Service</h5>
      <p>
        Gramapays  goes beyond just providing products; we are dedicated to offering top-notch customer service. Whether you have questions about a product, need help with an order, or want to give feedback, our support team is always here to assist you, ensuring a hassle-free experience from start to finish.
      </p>
      
      <h5>Exclusive Deals and Offers</h5>
      <p>
        As a Gramapays  customer, you’ll enjoy access to exclusive deals, discounts, and promotions that make shopping even more enjoyable. Our commitment to affordability ensures that you get the best value for your money, without compromising on quality.
      </p>
      
      <h4>Join the Gramapays  Community</h4>
      <p>
        Gramapays  is more than an e-commerce platform—it's a community of smart shoppers who value quality, innovation, and trust. We invite you to join the Gramapays  family, explore our wide range of products, and experience shopping like never before.
      </p>
      <p>
        Thank you for choosing Gramapays . We look forward to helping you find the products you love and delivering an unmatched online shopping experience.
      </p>
      
      <h4>Frequently Asked Questions (FAQs)</h4>

      <h5>What products does Gramapays  offer?</h5>
      <p><strong>Answer:</strong> Gramapays  offers a wide variety of products ranging from electronics, home appliances, fashion, beauty products, to home decor and more. Whatever you need, you’ll find it on Gramapays .</p>
      
      <h5>How do I track my order on Gramapays ?</h5>
      <p><strong>Answer:</strong> After placing an order, you will receive an email with a tracking number and a link to track your package. You can also track your order directly from your Gramapays  account under "My Orders."</p>
      
      <h5>What is Gramapays 's return policy?</h5>
      <p><strong>Answer:</strong> Gramapays  offers a hassle-free return policy. If you're not satisfied with your purchase, you can initiate a return within 30 days of delivery for a full refund or exchange, depending on the product category.</p>
      
      <h5>Does Gramapays  offer free shipping?</h5>
      <p><strong>Answer:</strong> Yes, Gramapays  offers free shipping on orders over a certain amount. Check our shipping policy for more details and specific eligibility criteria.</p>
      
      <h5>What payment methods does Gramapays  accept?</h5>
      <p><strong>Answer:</strong> Gramapays  accepts various payment methods, including credit/debit cards, PayPal, and other secure online payment options. You can choose your preferred payment method at checkout.</p>
    </div>
  );
}

export default AboutUs;
